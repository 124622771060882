import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';

import AOS from 'aos';
import 'aos/dist/aos.css';

import './SectionSteps.scss';

class SectionSteps extends Component  {

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <section className="content-pos" id="SectionSteps">
        <div className="container-grid">
          <div className="grid-a">
            <div className="pos">
              <div className="pos-header">
                <div
                  className="pos-header__logo"
                  data-aos="fade-up"
                  data-aos-once="true"
                  >
                  <img src="./img/010_icon_hotlink.png" alt="hotlink_logo"/>
                </div>
                <div
                  className="pos-header__title text-green-dark-beluga"
                  data-aos="fade-up"
                  data-aos-once="true"
                  >
                  <h1>
                    <Trans i18nKey="sectionSteps.tittleBold" />
                  </h1>
                  <h2>
                    <Trans i18nKey="sectionSteps.tittleRegular" />
                  </h2>
                </div>
              </div>
              <div className="pos-content">
                <p
                  data-aos="fade-up"
                  data-aos-once="true"
                  >
                    <Trans i18nKey="sectionSteps.paragraph1" />
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-once="true"
                  >
                    <Trans i18nKey="sectionSteps.paragraph2Begin" />
                    <span className="text-blue-dark-beluga">
                      <Trans i18nKey="sectionSteps.paragraph2Span" />
                    </span> 
                    <Trans i18nKey="sectionSteps.paragraph2Last" />
                </p>
                <p
                  data-aos="fade-up"
                  data-aos-once="true"
                  >
                    <Trans i18nKey="sectionSteps.paragraph3" />
                </p>
              </div>
            </div>
          </div>
          <div className="grid-b">
            <div>
              {/* <img
                data-aos="fade-up"
                data-aos-once="true"
                className="img-compu"
                src="/img/011_imagenhotlink.png"
                alt=""/> */}
            </div>
          </div>
        </div>
      </section>
    )
  } 
}

export default withTranslation()(SectionSteps);
