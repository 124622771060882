const en = {
    tittleBold: 'Hot Link',
    tittleRegular: 'Accept payments easily',
    paragraph1: 'Offers multiple credit card payment options',
    paragraph2Begin: 'With our ',
    paragraph2Span: 'Hot Link ',
    paragraph2Last: 'your clients will be able to pay using a web link that can be shared via email or message',
    paragraph3: 'From the window which link is sent they can enter the data of any type of credit card to process the payment, and voila!'
};

const es = {
    tittleBold: 'Hot Link',
    tittleRegular: 'Recibe pagos fácilmente',
    paragraph1: 'Ofrece múltiples opciones de pago con tarjeta de crédito',
    paragraph2Begin: 'Con nuestro ',
    paragraph2Span: 'Hot Link ',
    paragraph2Last: 'tus clientes podrán acceder a través de nuestra solución al monto a pagar por medio de un link/url que les compartes a cualquier servicio de mensajería móvil o a su correo',
    paragraph3: 'Desde la ventana que se envía, ellos podrán introducir los datos de cualquier tipo de tarjeta de crédito para procesar el pago, y listo!'
};

module.exports = {
    en,
    es
};