import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';

import './SectionWithBeluga.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

class SectionWithBeluga extends Component  {

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
        <section className="section-withBeluga flex" id="SectionWithBeluga">
          <div className="content flex responsive">
            <div className="content-header">
              <div
                className="content-header__logo auto"
                data-aos="fade-up"
                data-aos-once="true"
                >
                  <img src="./img/014_icon_dash.png" alt="dash_icon"/>
              </div>
              <div
                className="content-header__title auto"
                data-aos="fade-up"
                data-aos-once="true"
                >
                  <h1 className="text-green-dark-beluga">
                      <span>
                        <Trans i18nKey="sectionWithBeluga.tittleBold" />
                      </span> 
                      <Trans i18nKey="sectionWithBeluga.tittleRegular" />
                  </h1>
              </div>
            </div>
            <div>
              <div
                className="content-description"
                data-aos="fade-up"
                data-aos-once="true"
                >
                <p>
                  <Trans i18nKey="sectionWithBeluga.paragraph1" />
                </p>
                <p>
                  <Trans i18nKey="sectionWithBeluga.paragraph2" />
                </p>
                <p>
                <Trans i18nKey="sectionWithBeluga.paragraph3" />
                </p>
                <p>
                <Trans i18nKey="sectionWithBeluga.paragraph4" />
                </p>
                <p>
                <Trans i18nKey="sectionWithBeluga.paragraph5" />
                </p>
                <p>
                <Trans i18nKey="sectionWithBeluga.paragraph6" />
                </p>
              </div>
            </div>
          </div>
        </section>
    )
  } 
}

export default withTranslation()(SectionWithBeluga);
