const en = {
   tittleBold: 'Our ecommerce ',
   tittleRegular: 'without complications',
   paragraph1: 'Accept payments in your webstore, shopping cart <br/> or App. Our tool is ready to <br/> adapt to your project',
   paragraph2: 'Plus, you can also review all of your transactions <br/> in real time from our dashboard and if <br/> you have more products at our gateway, <br/> all can be managed from one single place',
   paragraph3: 'Your project can accept payments with national and foreign currency',
   paragraph4: 'Every single one of our E-commerce services operates with:',
 };
 
 const es = {
    tittleBold: 'Nuestro ecommerce ',
    tittleRegular: 'sin complicaciones',
    paragraph1: 'Acepta pagos en tu sitio web, carrito de compras <br/> o App. Nuestra herramienta esta lista para <br/> adaptarse a tu proyecto.',
    paragraph2: 'Además, puedes consultar todas tus transacciones en tiempo real desde nuestro dashboard y si cuentas con mas productos de nuestra pasarela, todo lo podrás gestionar desde el mismo lugar.',
    paragraph3: 'Ya sea moneda nacional o extranjera tu proyecto podrá cobrar con cualquiera de esas divisas',
    paragraph4: 'Todos nuestros servicios de E-Commerce cuentan con:',
 };
 
 module.exports = {
     en,
     es
 };