import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import {
  Link
} from "react-router-dom";
import { withTranslation, Trans } from 'react-i18next';

import './Menu.scss';

class Menu extends Component {

  // changeLanguage(lng) {
  //   i18n.changeLanguage(lng);
  // }

  render() {
    return (
      <div className={`container-menu ${this.props.mode}`}>
        <div className="container-logo">
            <img className="image-logo" src="./img/016_logoespiralapp-8.png" alt="logoBeluga"/>
        </div>
        <div className="container-bts">
          <ul className="nav__list">
            <li className="nav__item">
              <a href="#sectionHeader" className="nav__link">
                <Trans i18nKey="navbar.opt1" />
              </a>
            </li>
            <li className="nav__item">
              <a href="#SectionNoComplications" className="nav__link">
                <Trans i18nKey="navbar.opt2" />
              </a>
            </li>
            <li className="nav__item">
              <a href="#SectionWhatWeDo" className="nav__link">
                <Trans i18nKey="navbar.opt3" />
              </a>
            </li>
            <li className="nav__item">
              <a href="#SectionSteps" className="nav__link">
                <Trans i18nKey="navbar.opt4" />
              </a>
            </li>
            <li className="nav__item">
              <a href="#SectionWithBeluga" className="nav__link">
                <Trans i18nKey="navbar.opt5" />
              </a>
            </li>
            <li className="nav__item">
              <a href="#SectionWhiteLabel" className="nav__link">
                <Trans i18nKey="navbar.opt6" />
              </a>
            </li>
            <li className="nav__item">
              <a href="#SectionTrademarks" className="nav__link">
                <Trans i18nKey="navbar.opt7" />
              </a>
            </li>
            <li className="nav__item">
              <a href="#SectionGetEspiral" className="nav__link">
                <Trans i18nKey="navbar.opt8" />
              </a>
            </li>
            <li className="nav__item">
              <a href="#SectionFooter" className="nav__link">
                <Trans i18nKey="navbar.opt9" />
              </a>
            </li>
          </ul>
          
          <div className="buttons">
            <a href="https://dash.espiralapp.com/es/login" target="_blank" rel="noopener noreferrer">
              <Button className="btn-menu">
                <Trans i18nKey="navbar.btn1" />
              </Button> 
            </a>
            <a href="tel:+3317216417">
              <Button className="btn-menu call">
                <Trans i18nKey="navbar.btn2" />
              </Button> 
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Menu);