const en = {
    tittleBold: 'With EspiralApp ',
    tittleRegular: 'you can check your dashboard...',
    paragraph1: 'Where a transaction was made, on the day and time you require.',
    paragraph2: 'All your transactions in one place.',
    paragraph3: 'Manage all of our products.',
    paragraph4: 'Reconcile your sales immediately.',
    paragraph5: 'Download weekly or monthly reports, do not lose the thread of your sales.',
    paragraph6: 'Monitor the status of your sales in real time.'
};

const es = {
    tittleBold: 'Con Espiralapp ',
    tittleRegular: 'puedes revisar en tu dashboard...',
    paragraph1: 'En donde fue realizada una transacción, en el día y la hora que lo requieras.',
    paragraph2: 'Todas tus transacciones en un solo lugar.',
    paragraph3: 'Administra todos nuestros productos.',
    paragraph4: 'Concilia tus ventas de forma inmediata.',
    paragraph5: 'Descarga reportes semanales o mensuales, no pierdas el hilo de tus ventas.',
    paragraph6: 'Revisa el estatus de tus transacciones al momento de realizarlas.'
};

module.exports = {
    en,
    es
};