import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';

import './SectionWhatWeDo.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

class SectionWhatWeDo extends Component  {

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div className="gradient" id="SectionWhatWeDo">
        <section className="section-ecommerce">
          <div className="container-grid">
            <div className="grid-a">
              <div className="compu-containter">
                <img
                  data-aos="fade-up"
                  data-aos-once="true"
                  className="img-compu"
                  src="/img/009_Compu.png"
                  alt=""/>
              </div>
            </div>
            <div className="grid-b content">
              <div>
                <div
                  className="content-title"
                  data-aos="fade-up"
                  data-aos-once="true"
                  >
                  <h1>
                    <Trans i18nKey="sectionWhatWeDo.tittleBold" />
                    <span>
                      <Trans i18nKey="sectionWhatWeDo.tittleRegular" />
                    </span>
                  </h1>
                </div>            
                <div
                  className="content-description"
                  data-aos="fade-up"
                  data-aos-once="true"
                  >
                  <p className="auto">
                    <span>
                      <Trans i18nKey="sectionWhatWeDo.paragraph1Bold" />
                    </span>
                    <Trans i18nKey="sectionWhatWeDo.paragraph1Regular" />
                  </p>
                </div>
                <div
                  className="content-comision mob-center"
                  data-aos="fade-up"
                  data-aos-once="true"
                  >
                  <p className="auto">
                    <Trans i18nKey="sectionWhatWeDo.paragraph2Regular" />
                  </p>
                  <h1>
                    <Trans i18nKey="sectionWhatWeDo.paragraph2Bold" />
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  } 
}

export default withTranslation()(SectionWhatWeDo);
