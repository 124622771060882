const en = {
    tittleBold: 'Adapt',
    tittleRegular: ' our tool to your project!',
    subT1: "API DOC's",
    paragraph1: 'Documentation to implement transactions, cancellations, refunds, reversals and verification of transactions',
    subT2: 'API CHECKOUT',
    paragraph2: 'Implementation of charges through our gateway.',
    subT3: 'PLUGINS'
};

const es = {
    tittleBold: 'Adapta',
    tittleRegular: ' nuestra herramienta a tu proyecto!',
    subT1: "API DOC's",
    paragraph1: 'Documentación para implementar transacciones, cancelaciones, reembolsos, reversasy verificacion de transacciones',
    subT2: 'API CHECKOUT',
    paragraph2: 'Implementación de cobros mediante nuestra pasarela',
    subT3: 'PLUGINS'
};

module.exports = {
    en,
    es
};