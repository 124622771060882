import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';

import AOS from 'aos';
import 'aos/dist/aos.css';

import './SectionWhiteLabel.scss';

class SectionWhiteLabel extends Component  {

    componentDidMount() {
        AOS.init({
            duration: 800,
            offset: 200
        });
    }

  render() {
    return (
        <div className="wl-gradient" id="SectionWhiteLabel">
            <section className="content-wl">
                <div className="wl">
                    <div className="wl-header">
                        <div
                            className="wl-header__title text-green-dark-beluga"
                            data-aos="fade-up"
                            data-aos-once="true"
                            >
                            <h1>
                                <span>
                                    <Trans i18nKey="sectionWhiteLabel.tittleBold" />
                                </span>
                                <Trans i18nKey="sectionWhiteLabel.tittleRegular" />
                            </h1>
                        </div>
                    </div>
                    <div className="wl-content">
                        <h2
                            data-aos="fade-up"
                            data-aos-once="true">
                            <Trans i18nKey="sectionWhiteLabel.subT1" />
                        </h2>
                        <p
                            data-aos="fade-up"
                            data-aos-once="true"
                            >
                            <Trans i18nKey="sectionWhiteLabel.paragraph1" />
                        </p>
                        <h2
                            data-aos="fade-up"
                            data-aos-once="true"
                            >
                            <Trans i18nKey="sectionWhiteLabel.subT2" />
                        </h2>
                        <p
                            data-aos="fade-up"
                            data-aos-once="true"
                            >
                            <Trans i18nKey="sectionWhiteLabel.paragraph2" />
                        </p>
                       <h2
                        data-aos="fade-up"
                        data-aos-once="true"
                        >
                            <Trans i18nKey="sectionWhiteLabel.subT3" />
                        </h2>
                        <a href="https://github.com/belugapaydevs/Ecommerce-Plugin-WooCommerce" target="_blank" rel="noopener noreferrer">
                            <img
                                src="./img/016_woo.png"
                                alt="woo_icon"
                                data-aos="fade-up"
                                data-aos-once="true"
                            />
                        </a>
                    </div>
                </div>
            </section>
        </div>
    )
  } 
}

export default withTranslation()(SectionWhiteLabel);
