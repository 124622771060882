const en = {
    opt1: 'Accept payments',
    opt2: 'Without complications',
    opt3: 'Friendly gateway',
    opt4: 'Hot link',
    opt5: 'Dashboard',
    opt6: 'White label',
    opt7: 'Certifications and alliances',
    opt8: 'Get Espiralapp',
    opt9: 'Contact us',
    btn1: 'Create your user',
    btn2: 'Call us'
};

const es = {
    opt1: 'Acepta pagos',
    opt2: 'Sin complicaciones',
    opt3: 'Ecommerce amigable',
    opt4: 'Enlace de cobro',
    opt5: 'Dashboard',
    opt6: 'White label',
    opt7: 'Certificaciones y alianzas',
    opt8: 'Obten Espiralapp',
    opt9: 'Contáctanos',
    btn1: 'Crea tu usuario',
    btn2: 'Llamanos'
};

module.exports = {
    en,
    es
};
