import React, { Component } from 'react';
import {
  Link
} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { withTranslation, Trans } from 'react-i18next';
import AOS from 'aos';
import 'aos/dist/aos.css';

import './Footer.scss';

class Footer extends Component {

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <footer id="SectionFooter">
        <Container maxWidth="xl" className="footer-vert-gap" id="txt-white">
          <Grid container spacing={1} className="center">
            <Grid item sm={12} xs={12}>
              <div className="container-footer-info">
                <div
                  data-aos="fade-up"
                  data-aos-once="true"
                  >
                  <img src="./img/016_logoespiralapp-8.png" className="image-logo" alt="logoEspiralapp" />
                </div>
                <div
                  className="footer-mail"
                  data-aos="fade-up"
                  data-aos-once="true"
                  >
                  <p className="link">
                    <a href="tel:+3317216417">
                      33172 16417
                    </a>
                  </p>
                </div>
                <div
                  className="footer-follow"
                  >
                  <Trans i18nKey="sectionContact.followUs" />
                </div>
                <div>
                  <a target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/MyEspiralApp">
                    <img src="./img/019_logo_facebook.svg" className="footer-social" alt="facebook" />
                  </a>
                  <a target="_blank" rel="noreferrer noopener" href="https://www.instagram.com/myespiralapp/">
                    <img src="./img/020_logo_instagram.svg" className="footer-social" alt="instagram" />
                  </a>
                  {/* <a target="_blank" rel="noreferrer noopener" href="https://t.me/belugapay">
                    <img src="./img/021_logo_telegram.svg" className="footer-social" alt="telegram" />
                  </a> */}
                  <a target="_blank" rel="noreferrer noopener" href="https://www.linkedin.com/company/myespiralapp">
                    <img src="./img/022_logo_linkedin.svg" className="footer-social" alt="linkedin" />
                  </a>
                  <a target="_blank" rel="noreferrer noopener" href="https://twitter.com/MyEspiralApp">
                    <img src="./img/023_logo_twitter.svg" className="footer-social" alt="twitter" />
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>

        <Container maxWidth="xl">
          <div className="footer text-center light">
            <Trans i18nKey="sectionContact.conditions" />
          </div>
        </Container>
      </footer>
    )
  }
}

export default withTranslation()(Footer);
