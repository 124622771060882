import navbar from './sections/navbar';
import sectionHeader from './sections/sectionHeader';
import sectionNoComplications from './sections/sectionNoComplications';
import sectionWhatWeDo from './sections/sectionWhatWeDo';
import sectionSteps from './sections/sectionSteps';
import sectionWithBeluga from './sections/sectionWithBeluga';
import sectionWhiteLabel from './sections/sectionWhiteLabel';
import sectionTrademarks from './sections/sectionTrademarks';
import sectionDownload from './sections/sectionDownload';
import sectionContact from './sections/sectionContact';

export default {
  en: {
    navbar: navbar.en,
    sectionHeader: sectionHeader.en,
    sectionNoComplications: sectionNoComplications.en,
    sectionWhatWeDo: sectionWhatWeDo.en,
    sectionSteps: sectionSteps.en,
    sectionWithBeluga: sectionWithBeluga.en,
    sectionWhiteLabel: sectionWhiteLabel.en,
    sectionTrademarks: sectionTrademarks.en,
    sectionDownload: sectionDownload.en,
    sectionContact: sectionContact.en
  },

  es: {
    navbar: navbar.es,
    sectionHeader: sectionHeader.es,
    sectionNoComplications: sectionNoComplications.es,
    sectionWhatWeDo: sectionWhatWeDo.es,
    sectionSteps: sectionSteps.es,
    sectionWithBeluga: sectionWithBeluga.es,
    sectionWhiteLabel: sectionWhiteLabel.es,
    sectionTrademarks: sectionTrademarks.es,
    sectionDownload: sectionDownload.es,
    sectionContact: sectionContact.es,
  }
}
