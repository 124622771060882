const en = {
    tittleBold: 'Our ecommerce <br/>',
    tittleRegular: 'is a friendly payment gateway, secure and reliable',
    paragraph1Bold: 'With EspiralApp ',
    paragraph1Regular: 'take your bussines to the next level and start accepting payments',
    paragraph2Regular: 'Commissions of:',
    paragraph2Bold: '2.99% + $ 2 MXN + VAT'
};

const es = {
    tittleBold: 'Nuestro ecommerce <br/>',
    tittleRegular: 'es una pasarela de pagos amigable, segura y confiable',
    paragraph1Bold: 'Con Espiralapp ',
    paragraph1Regular: 'lleva tu negocio al siguiente nivel y comienza a cobrar.',
    paragraph2Regular: 'Comisiones de:',
    paragraph2Bold: '2.99% + $ 2 MXN + IVA'
};

module.exports = {
    en,
    es
};