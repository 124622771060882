import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';

import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import AOS from 'aos';
import 'aos/dist/aos.css';

import './SectionDownload.scss';

class SectionDownload extends Component  {
  
  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  render() {
    return (
      <div className="section-get-espiral" id="SectionGetEspiral">
        <div className="espiral-content">
          <h1>
            <span>
              <Trans i18nKey="sectionDownload.get" />
            </span>
            <span> 
              <Trans i18nKey="sectionDownload.espiral" />
            </span>
            <span>
              <Trans i18nKey="sectionDownload.ecommerce" />
            </span>
          </h1>
          <div className="header__button text-center">
            <a href="tel:+3317216417">
              <Button className="btn-send" variant="outlined">
                <Trans i18nKey="sectionDownload.contact" />
              </Button>
            </a>
          </div>
          <div>
            <p>
              <Trans i18nKey="sectionDownload.paragraphReg" />
              <span>
                <Trans i18nKey="sectionDownload.paragraphBold" />
              </span>
            </p>
          </div>
          <a href="https://dash.espiralapp.com/es/login" target="_blank" rel="noopener noreferrer">
            <Button className="btn-send" variant="outlined">
              <Trans i18nKey="sectionDownload.createAcc" />
            </Button>
          </a>
        </div>
      </div>
    )
  } 
}

export default withTranslation()(SectionDownload);
