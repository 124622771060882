import React, { Component } from 'react';
import { Element } from 'react-scroll';
import { withTranslation, Trans } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';

import Footer from './../footer/Footer';

import AOS from 'aos';
import 'aos/dist/aos.css';

import './SectionContact.scss';

class SectionContact extends Component  {

  state = {
    email: '',
    comment: '',
    openMessageAlert: false,
    messageAlert: ''
  }
  
  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  handleChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleMessageAlertOpen(message) {
    this.setState({
      openMessageAlert: true,
      messageAlert: message
    });
  };

  handleMessageAlertClose() {
    this.setState({
      openMessageAlert: false
    });
  };

  sendEmail() {
    if (this.state.email !== '' && this.state.comment !== '') {
      fetch('https://email.espiralapp.com/contact', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
          email: this.state.email,
          comment: this.state.comment
        })
      })
      .then(response => response.json())
      .then(response => {
        if (response.payload) {
          this.handleMessageAlertOpen('Message sent');
          this.setState({
            email: '',
            comment: ''
          });
        } else {
          this.handleMessageAlertOpen(`Error: ${response.msg}`);
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }

  render() {
    return (
      <div className="sectionContact">
        <div className="container-contact">
          <div
            className="container-form"
            data-aos="fade-up"
            data-aos-once="true">
            <Element name="contact"></Element>
            <div className="container-contact-info">
              <div>
                <TextField
                  className="w-100"
                  label= {this.props.t('sectionContact.email')}
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange.bind(this)}
                  margin="normal"
                />
                <TextField
                  className="w-100 mt-4"
                  label= {this.props.t('sectionContact.comments')}
                  name="comment"
                  multiline
                  rows="4"
                  value={this.state.comment}
                  onChange={this.handleChange.bind(this)}
                  margin="normal"
                />
              </div>
              <div>
                <div className="title-contact">
                <Trans i18nKey="sectionContact.hear" />
                </div>
                <div>
                  <p className="assessor">
                    <Trans i18nKey="sectionContact.paragRegular" />
                    <span className="semi-bold">
                      <Trans i18nKey="sectionContact.paragBold" />
                    </span>
                      <Trans i18nKey="sectionContact.paragRegular2" />
                  </p>
                  <Button className="btn-send" variant="outlined" onClick={this.sendEmail.bind(this)}>
                  <Trans i18nKey="sectionContact.send" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="container-footer">
            <Footer />
          </div>
        </div>
        <Snackbar
          open={this.state.openMessageAlert}
          onClose={this.handleMessageAlertClose.bind(this)}
          onClick={this.handleMessageAlertClose.bind(this)}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.state.messageAlert}</span>}
        />
      </div>
    )
  } 
}

export default withTranslation()(SectionContact);
