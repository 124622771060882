import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import Button from '@material-ui/core/Button';
import i18n from './../../../i18n';

import './SectionHeader.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

class SectionHeader extends Component {

  state = {
    open: false,
    scroll: 'paper'
  }

  componentDidMount() {
    AOS.init({
      duration: 800,
      offset: 200
    });
  }

  handleClickOpen () {
    this.setState({
      open: true
    });
  };

  handleClose () {
    this.setState({
      open: false
    });
  };

  changeLanguage(lng) {
    i18n.changeLanguage(lng);
  }

  
  render() { 
    return (
      <div className="section-header" id="sectionHeader">
        <div
          className="section-background"
          data-aos="fade-up"
          data-aos-once="true"
          >
          <img className="header__logo" src="./img/001_Logobanner.png" alt="logo"/>
          <div className="header__phone">
            <div className="header__language">
              <p onClick={() => this.changeLanguage('es')}>
                <img className="flag-lang" src="/img/mexico.png" alt="Español" />
              </p>
              <p onClick={() => this.changeLanguage('en')}>
                <img className="flag-lang" src="/img/001-reino-unido.png" alt="Español" />
              </p>
            </div>
            <a href="tel:+3317216417">
              33172 - 16417
            </a>
          </div>
          <div className="ml-5">
            <div
              className="section-text-center"
              data-aos="fade-up"
              data-aos-once="true"
              >
                <h1 className="bold"><Trans i18nKey="sectionHeader.accept" /></h1>
                <p className="payments bold"><Trans i18nKey="sectionHeader.paymentsToday" /></p>
            </div>
            <div
              className="header__button text-center"
              data-aos="fade-up"
              data-aos-once="true"
              >
              <a href="tel:+3317216417">
                <Button variant="outlined">
                <Trans i18nKey="sectionHeader.contact" />
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  } 
}

export default withTranslation()(SectionHeader);