
function findDevice() {
  return deviceIs();
}

function deviceIs() {
  if (window.outerWidth >= 1024) {
    return 'desktop';
  } else if (window.outerWidth >= 768) {
    return 'tablet';
  } else if (window.outerWidth >= 0) {
    return 'mobile';
  } else {
    return null;
  }
}

module.exports = {
  findDevice
}