const en = {
    tittleBold: 'Certifications',
    tittleRegular: ' and alliances',
};

const es = {
    tittleBold: 'Certificaciones',
    tittleRegular: ' y alianzas',
};

module.exports = {
    en,
    es
};