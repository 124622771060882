import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';

import { findDevice } from './../../utils/devices';

import './SectionNoComplications.scss';

import AOS from 'aos';
import 'aos/dist/aos.css';

class SectionNoComplications extends Component  {
  deviceIs = null;

  constructor(props) {
    super(props);
    window.addEventListener('resize', () => {
      this.setState({
        deviceIs: findDevice()
      });
    });
  }

  componentDidMount() {
    this.setState({
      deviceIs: findDevice()
    });

    AOS.init({
      duration: 800,
      offset: 200
    });
	}

  handleClickOpen () {
    this.setState({
      open: true
    });
  };

  handleClose () {
    this.setState({
      open: false
    });
  };

  render() {
    return (
      <section className="content-demo" id="SectionNoComplications">
        <div className="info">
          <div
            className="info-header flex"
            data-aos="fade-up"
            data-aos-once="true"
            >
            <div className="info-header__logo">
              <img src="./img/004_icon_ecommerce.png" alt="terminal"/>
            </div>
            <div className="info-header__title text-green-dark-beluga">
              <h1>
                <Trans i18nKey="sectionNoComplications.tittleBold" />
              </h1>
              <h2>
                <Trans i18nKey="sectionNoComplications.tittleRegular" />
              </h2>
            </div>
          </div>
          <div
            className="info-content"
            data-aos="fade-up"
            data-aos-once="true"
            >
            <p>
              <Trans i18nKey="sectionNoComplications.paragraph1" />
            </p>
            <p>
              <Trans i18nKey="sectionNoComplications.paragraph2" />
            </p>
            <p>
              <Trans i18nKey="sectionNoComplications.paragraph3" />
            </p>
            <p>
              <Trans i18nKey="sectionNoComplications.paragraph4" />
            </p>
            <div
              className="cards flex responsive"
              data-aos="fade-up"
              data-aos-once="true"
              >
              <img src="./img/005_logo_mastercard.png" alt="mc_logo"/>
              <img src="./img/007_visa.png" alt="visa_logo"/>
              <img src="./img/008_carnet.png" alt="carnet_logo"/>
              <img src="./img/008_americanexpress-8.png" alt="carnet_logo"/>
            </div>
          </div>
        </div>
      </section>
    )
  } 
}

export default withTranslation()(SectionNoComplications);